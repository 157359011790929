import React, { useState } from "react";
import { useScrollPosition } from "@n8tb1t/use-scroll-position";
import Scrollspy from "react-scrollspy";
import { BsThreeDotsVertical } from "react-icons/bs";
import { RiCloseLine } from "react-icons/ri";
import PropTypes from "prop-types";

import tw, { css, styled } from "twin.macro";

import shortid from "shortid";

import { urlify, useWindowWidth } from "@helpers";

import { ButtonText } from "@components/shared";

const TableOfContents = ({ className, list, rail }) => {
  const windowWidth = useWindowWidth();

  const [progress, setProgress] = useState(0);

  const [isListVisible, setIsListVisible] = useState(true);
  const [isOpen, setIsOpen] = useState(false);

  useScrollPosition(
    () => {
      setIsOpen(false);

      const { top, bottom } = rail.current.getBoundingClientRect();
      const railScrollHeight = bottom - top;

      if (windowWidth > 1280) {
        if (top < 0) setProgress(((top * -1) / railScrollHeight) * 100);
        else setProgress(0);
      }

      setIsListVisible(bottom > 0);
    },
    ...Array(3),
    50
  );

  const renderList = () => (
    <Scrollspy
      items={[...list.map((offer) => urlify(offer.title))]}
      currentClassName="isOfferActive"
      className={className}
    >
      {list.map((offer) => (
        <li key={shortid.generate()}>
          <ButtonText
            to={`/oferta#${urlify(offer.title)}`}
            tw="font-bold text-primary-light"
          >
            {offer.title}
          </ButtonText>
        </li>
      ))}
    </Scrollspy>
  );

  return (
    <>
      <div style={tw`sticky top-16`}>
        {renderList()}
        <span
          className="progressBar"
          tw="absolute top-0 left-0 block w-1 mt-2 box-border bg-primary"
          style={{ height: `${progress}%` }}
        />
      </div>
      {!isListVisible && (
        <button
          css={[
            tw`xl:hidden fixed top-24 right-menu z-20 bg-primary text-white mr-1 p-3 rounded-full shadow-md transition duration-75 ease-in-out focus:outline-none cursor-pointer`,
            css`
              animation: slideInLeft 100ms ease-in forwards;
            `,
            isOpen && tw`bg-primary-pale text-primary shadow-none`,
          ]}
          onClick={() => setIsOpen(!isOpen)}
        >
          {isOpen ? <RiCloseLine /> : <BsThreeDotsVertical />}
        </button>
      )}
      <div
        className={className}
        css={[
          tw`fixed top-24 to-sm:left-menu right-menu z-10 mr-1 px-12 py-10 bg-primary-pale shadow-lg rounded-t-top rounded-b-bottom opacity-0 pointer-events-none transform scale-75 origin-top-right transition duration-75 ease-in`,
          !isListVisible &&
            isOpen &&
            tw`opacity-100 pointer-events-auto scale-100`,
        ]}
        aria-hidden="true"
        tabIndex="-1"
      >
        {renderList()}
      </div>
    </>
  );
};

TableOfContents.propTypes = {
  className: PropTypes.string.isRequired,
  list: PropTypes.array.isRequired,
  rail: PropTypes.object.isRequired,
};

export default styled(TableOfContents)`
  & .progress-bar {
    ${tw`transition duration-300 ease-in`}
  }

  & li {
    ${tw`my-0 to-xl:before:bg-primary-light xl:before:opacity-0 truncate`}

    &.isOfferActive {
      ${tw`to-xl:before:bg-primary`}

      a {
        ${tw`text-primary`}
      }
    }
  }
`;
