/* eslint-disable react/display-name */
import PropTypes from "prop-types";
import React from "react";

import "twin.macro";

import { Heading, Textbox, Image } from "@components/shared";
import { urlify } from "@helpers";

const Offer = React.forwardRef(
  ({ title, description, image, ...props }, ref) => (
    <article
      tw="not-first-of-type:mt-32 text-center"
      id={urlify(title)}
      ref={ref}
      {...props}
    >
      <Image tw="mb-12" fluid={image.asset.fluid} alt={image.alt} />
      <Heading tw="text-2xl" dangerouslySetInnerHTML={{ __html: title }} />
      <Textbox blocks={description} tw="w-container md:w-3/4 mx-auto" />
    </article>
  )
);

Offer.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.array.isRequired,
  image: PropTypes.object.isRequired,
};

export default Offer;
