import React, { useRef } from "react";
import PropTypes from "prop-types";

import { graphql } from "gatsby";

import "twin.macro";

import shortid from "shortid";

import { Layout } from "@components/layout";
import { Heading, Textbox } from "@components/shared";
import { Offer, TableOfContents } from "@components/offerpage";

const OfferPage = ({ data }) => {
  const { _rawText, list } = data.sanityOfferPage;

  const rail = useRef(0);

  return (
    <Layout title="Oferta">
      <Heading tw="text-center mb-12">Oferta</Heading>
      <Textbox blocks={_rawText} tw="w-container md:w-7/10 xl:w-7/12 mx-auto" />
      <div tw="flex justify-between to-xl:flex-col mt-20 xl:mt-32">
        <div
          tw="flex items-start justify-center w-3/10 to-xl:w-full to-xl:mb-20 py-10 px-5 bg-primary-pale"
          ref={rail}
        >
          <TableOfContents list={list} rail={rail} />
        </div>
        <div tw="flex flex-col items-center justify-between w-2/3 to-xl:w-full">
          {list.map((offer) => (
            <Offer
              key={shortid.generate()}
              title={offer.title}
              description={offer._rawDescription}
              image={offer.image}
            />
          ))}
        </div>
      </div>
    </Layout>
  );
};

OfferPage.propTypes = {
  data: PropTypes.object.isRequired,
};

export const query = graphql`
  query OfferPageQuery {
    sanityOfferPage {
      _rawText
      list {
        title
        image {
          asset {
            fluid(maxWidth: 1720) {
              src
              sizes
              aspectRatio
              srcSet
              srcSetWebp
              srcWebp
              base64
            }
          }
          alt
        }
        _rawDescription
      }
    }
  }
`;

export default OfferPage;
